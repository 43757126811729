import { Component } from "react";
import { withRouter } from 'react-router-dom';
import { getEnv, loadedInIframe } from "../../helpers/GeneralHelper";
import { connect } from "react-redux";
import {hasGtmEnabled, isGdprRegion, isInHub} from "../../helpers/AppHelper";
import {getBrowserCookie, setBrowserCookie} from "../../helpers/CookieHelper";

class CookieScripts extends Component {
    constructor(props) {
        super(props);

        this.state = {
            appWasLoaded: false,
            appLoadError: null,
            scriptsLoaded: false
        }
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.appWasLoaded !== prevState.appWasLoaded) {
            return ({appWasLoaded: nextProps.appWasLoaded})
        }
        if (nextProps.appLoadError !== prevState.appLoadError) {
            return ({appLoadError: nextProps.appLoadError})
        }

        return null;
    }

    componentDidMount() {
        if (this.shouldWeLoadCookieScripts() === true) {
            if (loadedInIframe() || isInHub() === false) {
                // we let the product or the website handle the cookie stuff
            } else {
                if (this.checkIfWeNeedToInsertScriptsInHead()) {
                    this.insertScriptInHead();
                }
            }
        }
    }
    componentDidUpdate(prevProps, prevState, snapshot) { }
    checkIfWeNeedToInsertScriptsInHead = () => {
        if (isGdprRegion()) {
            if (this.userGaveHisConsent() === true) {
                // OptanonActiveGroups
                // OnetrustActiveGroups
                this.setWindowVariables();

                // calling the OptanonWrapper function
                this.inAppOptanonWrapper();
                return false;
            } else {
                // user is GDPR and has not given his consent yet
                return true;
            }
        } else {
            // user is non GDPR
            // if we dont have consent, we set it now
            if (this.userGaveHisConsent() === false) {
                this.setUserConsent();
            }
            this.setWindowVariables();

            // calling the OptanonWrapper function
            this.inAppOptanonWrapper();
            return false;
        }
    }
    userGaveHisConsent = () => {
        // we need to check the optanon and hubspot cookies
        // OptanonAlertBoxClosed | __hs_cookie_cat_pref
        let ret = false;
        if (this.hasClosedTheAlertBox() === true) {
            ret = true;
        }

        return ret;
    }
    setUserConsent = () => {
        // we only set consent if user is non GDPR
        if (isGdprRegion() === false) {
            let cookieObject = {};

            cookieObject.landingPath = "NotLandingPage";
            cookieObject.datestamp = (new Date()).toString();
            cookieObject.version = "3.6.22";
            cookieObject.groups = ",1,2,3,4,";
            cookieObject.AwaitingReconsent = false;

            let cookieString = new URLSearchParams(cookieObject).toString();
            let dateNowString = new Date().toISOString();
            setBrowserCookie("OptanonConsent", cookieString, {secure: true, "max-age": 31536000});
            setBrowserCookie("OptanonAlertBoxClosed", dateNowString, {secure: true, "max-age": 31536000});
            setBrowserCookie("__hs_cookie_cat_pref", "1:true,2:true,3:true", {secure: true, "max-age": 31536000});
        }
    }
    hasClosedTheAlertBox = () => {
        let ret = false;

        const alertBoxClosed = getBrowserCookie("OptanonAlertBoxClosed");
        if (alertBoxClosed) {
            // the date when the user closed the cookie bar
            const closedTime = new Date(alertBoxClosed).getTime();
            // cookie expires 1 year from when he closed it, so we're checking if 1 year has passed since
            const expireTime = closedTime + (365 * 24 * 3600 * 1000);
            const todayTime = new Date().getTime();

            ret = todayTime <= expireTime;
        }

        return ret;
    }
    setWindowVariables = () => {
        // OptanonConsent
        let optanonConsent = getBrowserCookie("OptanonConsent");
        let activeGroups = "";

        let cookieObject = JSON.parse('{"' + decodeURI(optanonConsent).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g,'":"').replace(/&quot;/g,'"') + '"}');
        if (cookieObject && cookieObject.hasOwnProperty("groups")) {
            activeGroups = cookieObject.groups;
            activeGroups = activeGroups.replaceAll(":1", "");

            window.OnetrustActiveGroups = activeGroups;
            window.OptanonActiveGroups = activeGroups;
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({ OnetrustActiveGroups: activeGroups });
            window.dataLayer.push({ OptanonActiveGroups: activeGroups });
            window.dataLayer.push({ "event": "CookiePreferencesLoaded" });
        }
    }
    inAppOptanonWrapper = () => {
        if (window.hasOwnProperty('checkoutAppContext') && window.checkoutAppContext.hasOwnProperty('gtmEnabled') && window.checkoutAppContext.gtmEnabled !== false) {
            /* Updated or loaded changed in some way */
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({'cookiePreferencesLoaded': 'true'});
            window.dataLayer.push({event: "CookiePreferencesLoaded"});
            window.dataLayer.push({event: "UserSessionAndCookiePreferencesLoaded"});
        }
    }
    insertScriptInHead = () => {
        const script = document.createElement("script"), env = getEnv();
        script.src = this.getScriptSrc(env);
        script.async = true;
        document.head.appendChild(script);

        this.setState({scriptsLoaded: true});
    };
    getScriptSrc = (env) => {
        let scriptName = 'eu_shared.js', scriptSrc = '';
        if (isGdprRegion() === false) {
            scriptName = 'non_' + scriptName;
        }

        switch(env) {
            case 'test':
            case 'development':
                scriptSrc = 'https://d3tvj3lw2y3r4c.cloudfront.net/webpage/test/js/cookies/' + scriptName;
                // scriptSrc = '/development_' + scriptName;
                break;
            default:
                scriptSrc = 'https://d3tvj3lw2y3r4c.cloudfront.net/webpage/prod3/js/cookies/' + scriptName;
        }

        return scriptSrc;
    };
    shouldWeLoadCookieScripts = () => {
        return (
            this.state.appWasLoaded === true &&
            typeof window.jQuery !== 'undefined' &&
            this.state.scriptsLoaded === false &&
            hasGtmEnabled() === true
        );
    };
    render() {
        return null;
    }
}

const mapStateToProps = (state) => {
    return {
        appWasLoaded: state.app.appLoaded,
        appLoadError: state.app.error
    }
};

export default withRouter(connect(mapStateToProps, null)(CookieScripts));
